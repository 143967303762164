<template>
  <div class="pageview">
    <van-sticky>
      <van-row>
        <van-col span="7"
          style="font-size: 16px;"
          class="call van-ellipsis--l1">
          <div class="sousuo"
            @click="showPopup">{{listfrom.OName}}
            <van-icon name="arrow-down" />
          </div>

          <van-popup v-model="show"
            round
            position="bottom">
            <van-cascader title="请选择所在地区"
              :options="options"
              @close="show = false"
              :field-names="{
                text: 'OrganName',
                value: 'OrganCode',
                children: 'children',
              }"
              @finish="onFinish" />
          </van-popup>
        </van-col>
        <van-col span="17">
          <van-search style="padding-left:0;"
            v-model="listfrom.name"
            shape="round"
            @blur="search"
            placeholder="请输入酒店名称" />
        </van-col>
      </van-row>
      <van-tabs @click="onClick"
        color="#31D984"
        title-active-color="#31D984">
        <van-tab v-for="item in TitleList"
          :key="item.Id"
          :name="item.Id"
          :title="item.Name">
        </van-tab>
      </van-tabs>
    </van-sticky>

    <div style="width:95%;margin:auto;padding-top:7px;">
      <van-list :loading="loading"
        :finished="finished"
        :offset="130"
        finished-text="没有更多了"
        @load="onLoad">

        <div class="information">
          <div class="center"
            style="padding:0;"
            v-for="item in list"
            :key="item.index">
            <div @click="tuijianClick('/travel/jingqu/jqXiangQIng/hotel/hotelXq/'+ item.HotelId)">
              <van-card style="padding: 10px 0px;background-color: white;">
                <template #thumb>
                  <div class="van-card__thumb">
                    <img v-if="item.ImgUrls == ''"
                      style="width:100%;height:100%;"
                      src="https://xczxapi.zhsq.cloud//upload/images/2022/5/5a80ec81-50a.png" />
                    <img v-else
                      style="width:100%;height:100%;"
                      :src="item.ImgUrls.split(',')[0]" />
                  </div>

                </template>
                <template #title>
                  <div class="centerTitle van-ellipsis--l1"
                    style="width: 75%;">{{ item.Name }}</div>
                  <!-- <div class="county">栾川县</div> -->
                </template>

                <template #tags>
                  <van-tag class="tagClass"
                    plain
                    type="danger"
                    color="#FFFFFF"
                    text-color="#66D161">{{item.CategoryDesc}}</van-tag>
                  <div style="width: 75%;"
                    class="van-multi-ellipsis--l2">{{item.Introduce}}</div>
                </template>
                <!-- <template #num>
                <div><span style="font-size:18px;font-weight: 550;color:red;">￥{{item.Cost}}</span>&nbsp;起&nbsp;</div>
              </template> -->
              </van-card>
            </div>
          </div>
        </div>

      </van-list>
    </div>
  </div>
</template>

<script>
import { WeGetHotelPage } from "@/api/jingqu";
import { setOCode, getOCode, setOName, getOName } from "@/utils/auth";
import { WeGetAppletOrganList } from "@/api/index";
export default {
  data () {
    return {
      show: false,
      options: [],
      activeNames: {},
      TitleList: [
        { Id: 1, Name: "旅馆" },
        { Id: 2, Name: "民宿" },
        { Id: 3, Name: "农家乐" },
      ],
      list: [],
      listfrom: {
        category: 1,
        page: 1,
        limit: 10,
        orgCode: '',
        OName: '',
        name: ''
      },
      hidden: true, //没有更多数据了
      loading: false, //加载
      finished: false, //数据完全加载结束为 true
    }
  },
  created () {
    this.gitocodelist()
    if (getOName() != '') {
      this.listfrom.orgCode = getOCode()
      this.listfrom.OName = getOName()
    }

    this.getHotelPage()
  },
  methods: {
    gitocodelist () {
      WeGetAppletOrganList().then((res) => {
        this.options = res.data.data;
      })
    },
    showPopup: function () {
      this.show = true
    },
    onFinish: function (val) {
      this.listfrom.OName = val.selectedOptions[0].OrganName;
      // this.listfrom.orgCode = val.selectedOptions[1].OCode;
      if (this.listfrom.OName == '洛阳市') {
        this.listfrom.orgCode = '';
      } else {
        this.listfrom.orgCode = val.value;
      }
      setOName(this.listfrom.OName);
      setOCode(this.listfrom.orgCode);
      // this.dataForm.OCode = val.value;
      this.show = false
      this.search()
    },
    // tab切换
    onClick (name, title) {
      this.list = []
      this.listfrom.category = name
      this.listfrom.page = 1
      this.getHotelPage()
    },
    // 搜索
    search: function () {
      this.list = []
      this.listfrom.page = 1
      this.listfrom.name = this.listfrom.name
      this.getHotelPage()
    },
    onLoad () {
      console.log(123);
      this.listfrom.page = this.listfrom.page + 1;
      this.getHotelPage();
    },
    // 酒店列表
    getHotelPage () {
      if (getOCode() != '') {
        this.listfrom.orgCode = getOCode()
      } else if (this.$route.params.Id == '1') {
        this.listfrom.orgCode = ''
      } else {
        this.listfrom.orgCode = this.$route.params.Id
      }
      WeGetHotelPage(this.listfrom).then((res) => {
        // this.jqTjList = res.data.data;
        // 加载状态结束
        let rows = res.data.data; //请求返回当页的列表
        this.loading = false;

        if (res.data.code == 0) {
          if (rows == null || rows.length === 0) {
            // 加载结束
            this.finished = true;
            return;
          }
          // 将新数据与老数据进行合并
          this.list = this.list.concat(rows);

          //如果列表数据条数>=总条数，不再触发滚动加载
          if (this.list.length >= res.data.count) {
            this.finished = true;
          }
        }
      })
    },
    // 酒店详情
    tuijianClick: function (path) {
      this.$router.push(path);
    },
  }
}
</script>

<style scoped>
.pageview {
  /* height: 100%; */
  /* background-color: #f8f8f8; */
  min-height: 100%;
}
.sousuo {
  height: 54px;
  background: white;
  line-height: 54px;
  padding-left: 15%;
  margin: auto;
  color: red;
}
.waterfall {
  /* width: 95%; */
  margin: 0 auto;
  column-count: 2;
  column-gap: 0;
  column-width: auto;
}
.waterfall .list {
  margin: 0px 7px 10px 7px;
  break-inside: avoid;
  border-radius: 5px;
  background-color: #ffffff;
}
.list img {
  width: 100%;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  /* height: 160px; */
  /* border-radius: 0px; */
}
.address {
  margin: 10px 0;
  color: #92600d;
  font-size: 14px;
}
.van-ellipsis--l1 {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  font-size: 16px;
}
.count {
  color: #000000;
  font-size: 17px;
}
.money {
  margin: 10px 0;
  padding-bottom: 10px;
  color: #fe5b47;
  font-size: 18px;
}
/* .center {
  border-bottom: 1px solid #e8e8e8;
}
.center:last-child {
  border-bottom: 0px solid #e8e8e8;
  padding: 10px;
} */
.tagClass {
  margin: 10px 0;
}
.van-card__thumb {
  position: relative;
  -webkit-box-flex: 0;
  -webkit-flex: none;
  flex: none;
  width: 110px;
  height: 110px;
  margin-right: 12px;
}
/deep/.van-tab {
  font-size: 16px;
}
</style>